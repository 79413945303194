@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-bg; /* set background color for entire website */
}

@layer components {
  .content a {
    @apply text-link hover:underline hover:opacity-80 transition-all duration-200;
  }
}